import React from 'react'
import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import IconBlocks from '../components/IconBlocks'
import ContentBlock from '../components/ContentBlock'

const ContactPage = () => (
  <Layout title="contact.subtitle">
  
    <ContentBlock content={[]} specialClass='header' />
    <ContentBlock content={[]} specialClass='header' />

    <div className="email">
        <a href="mailto:info@anvitapandey.com"> info@anvitapandey.com </a>
    </div>

    <ContentBlock content={[]} specialClass='header' />

    <IconBlocks />

  </Layout>
)

export default withIntl(ContactPage)
